/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(to right, #f7f7f7, #e7f5ec);
}

.contact-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Header Section */
.contact-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-header h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.contact-header p {
  font-size: 1rem;
  color: #666;
}

/* Main Section */
.contact-main {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.contact-info {
  flex: 1;
  text-align: center;

}

.contact-phone {
  width: 350px;
  margin-top: 20px;
}

.error{
  color: red;
  transition: blink();
}

.contact-phone:hover {
  border-radius: 80%;
}

.contact-number,
.contact-email {
  font-size: 1.2rem;
  margin: 10px 0;
}

.social-icons img {
  width: 32px;
  margin: 0 10px;
}

/* Form Section */
.contact-form {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background: #45a049;
}

/* Map Section */
/* Map Section */
.map-section {
  width: 100vw; /* Full viewport width */
  margin: 0; /* Reset any margin to avoid spacing issues */
  padding-top: 50px; /* Reset padding */
  position: relative;
  left: 50%; /* Center-align with respect to the viewport */
  transform: translateX(-50%); /* Center horizontally */
}

.map-section iframe {
  border: none;
  width: 100vw; /* Occupy the full width of the viewport */
  height: 450px; /* Adjust the height as needed */
}


/* Responsive */
@media (max-width: 768px) {
  .contact-main {
    flex-direction: column;
  }

.contact-content {
  flex-direction: row;
  justify-content: space-between;
}

.contact-info {
  width:100%;
  margin: 0 auto;
}
.contact-phone {
width: 300px;
transition: transform 0.2s ease;
}

.contact-phone:hover {
transform: scale(1.1); /* Slightly enlarges the icon on hover */
cursor: pointer;
}

/* Map Section */
.map-section {
width: 100vw; /* Full viewport width */
margin: 0; /* Reset any margin to avoid shifting */
padding-top: 30px; /* Reset padding */
position: relative;
left: 50%; /* Shift to align with viewport */
transform: translateX(-50%); /* Center align for full width */
}

.map-section iframe {
border: 0;
width: 100vw; /* Ensure iframe takes full viewport width */
height: 450px; /* Adjust height as needed */
margin: 0; /* Remove any additional margin */
}
}