.footer {
  background-color: #393939; /* Adjust background color */
  padding: 0 50px; /* Adjust padding */
  text-align: center; /* Center the footer text */
}

.footer-bottom {
  margin-top: 20px; /* Space between sections */
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-logos {
  width: 300px; /* Reduce the width to make it smaller */
  height: auto; /* Ensure it scales proportionally */
}

.footer-logos:hover {
  transform: scale(1.05);
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 150px;
  text-align: center;
}

.footer-section h4 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 15px;
  border-bottom: 2px solid skyblue;
  padding-bottom: 5px;
  display: inline-block;
}

.footer-section p {
  color: #ffffff;
  margin: 10px 0;
  font-size: 12px;
}

/* Style for Telephone and Email Links */
.contact-link {
  color: #ffffff; /* Default color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #87ceeb; /* Sky blue color on hover */
}

/* Styling for Social Media Link Buttons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-link {
  font-size: 20px;
  color: #a7a7a7;
  background-color: white; /* Gold background */
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #b8b8b8;
}

.social-link:hover {
  background-color: rgb(0, 0, 0); /* Darker shade for hover */
  color: #ffffff;
  border: solid 2px #fff;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9em;
  color: #ffffff;
  font-size: 15px;
}

.footer-bottom a {
  color: skyblue;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding: 30px 15px;
  }

  .footer-section {
    margin-bottom: 20px;
    min-width: 100%;
    text-align: center;
  }

  .footer-logos {
    width: 300px; /* Adjust logo size for smaller screens */
    margin-bottom: 15px;
  }

  .social-icons {
    flex-direction: row; /* Keep icons in a row on mobile */
    gap: 15px;
  }

  .social-link {
    font-size: 24px; /* Increase social icon size for mobile */
  }

  .footer-bottom {
    font-size: 13px; /* Smaller font size for footer text */
  }
}
