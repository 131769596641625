/* Navbar base styles */
.navbar {
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo styles */
.logos-image {
    width: 200px;
    height: auto;
    margin-left: 20px;
    margin-top: 10px;
}

.navbar .logos {
    display: flex;
    align-items: center;
}

/* Desktop menu wrapper */
.menu-wrapper {
    display: flex;
    align-items: center;
    letter-spacing: 2px;
}

/* Desktop menu styles */
.navbar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    padding-right: 100px;
}

.navbar-menu li {
    margin-left: 30px;
    font-size: 20px;
    font-weight: 800;
}

.navbar-menu a {
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 15px;
    border-radius: 5px;
    transition: transform 0.3s ease;
    margin-right: 20px;
}

.navbar-menu a:hover {
    color: #01e3f8;
    transform: scale(1.1);
}

.navbar img {
    transition: transform 0.3s ease-in-out;
}

.navbar img:hover {
    transform: scale(1.1);
}

/* Button styles */
.navbar .btn {
    background: none;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 800;
    font-size: 15px;
    position: relative;
    margin-right: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar .btn:hover {
    background-color: rgba(73, 231, 255, 0.8);
    border-radius: 50px;
}

/* Order Now Button Styles (for both mobile and desktop) */
.order-now-btn {
    background: none;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 800;
    font-size: 15px;
    position: relative;
    margin-left: 30px;
}

.order-now-btn {
    background: none;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;
    font-size: 15px;
    position: relative;
    margin-left: 30px;
}

.order-now-btn:hover {
    background-color: #49e7ff;
    border-radius: 50px;
}

/* Hamburger menu icon for mobile */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 15px;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
}

/* Mobile menu styles */
.mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #add8e6; /* Light blue background */
    padding: 10px;
    z-index: 1000;
    border-bottom: 1px solid #000; /* Black divider at the bottom */
    display: flex;
    flex-direction: column;
    align-items: center; /* Align all items to the center */
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
}

.mobile-menu li {
    margin: 20px 0;
    border-bottom: 1px solid #000; /* Black divider */
}

.mobile-menu a {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
}

/* Center the Order Now button in mobile view */
.mobile-menu .order-now-btn {
    margin: 20px auto; /* Center the button */
    display: block;
    background-color: #fff;
}

.mobile-menu .order-now-btn:hover {
    margin: 20px auto; /* Center the button */
    display: block;
    background-color: #49e7ff;
}

/* Close button in mobile menu */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 30px;
    cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
    .menu-wrapper {
        display: none;
    }
    .hamburger {
        display: flex;
    }
}

/* Desktop layout only */
@media (min-width: 769px) {
    .mobile-menu {
        display: none;
    }
}
