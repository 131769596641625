/* Welcome Section Styling */
/* Welcome Section Styling */
.welcome {
    background-size: cover;
    background-position: center;
    padding: 50px 20px;
    text-align: center;
    color: white; /* Or another color that contrasts well */
}

/* .welcome-content {
    background-color: rgba(0, 0, 0, 0.6); 
    display: inline-block;
    padding: 20px;
    border-radius: 10px;
} */

.welcome h1 {
    font-size: 36px; /* Adjust size as needed */
    font-weight: bold;
    margin: 0;
}

.welcome p {
    font-size: 18px; /* Adjust size as needed */
    margin: 10px 0;
}

.shop-now {
    background-color: #28a745; /* Green color for the button */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    font-size: 16px; /* Adjust size as needed */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.shop-now:hover {
    background-color: #218838; /* Darker green on hover */
}

/* Hero Section with Full-Height Slider */
html,
body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    width: 100%;     /* Ensure full width */
    overflow-x: hidden;/* Prevent horizontal overflow */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}

*,
*::before,
*::after {
    box-sizing: inherit; /* Apply box-sizing to all elements */
}

.hero {
    width: 100%; /* Full viewport width */
    height: auto; /* Full viewport height */
    overflow: hidden; /* Hide overflow */
    position: relative;
}

.slider {
    width: 100%; /* Ensure the slider takes full width */
    height: auto; /* Ensure the slider takes full height */
    overflow: hidden; /* Prevent overflow from slider */
}

.slider-image {
    width: 100%; /* Full viewport width */
    height: auto; /* Full viewport height */
    object-fit: cover; /* Ensures image fills the container while maintaining aspect ratio */
    display: block; /* Ensure images are treated as block elements */
}

.products-section {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background for separation */
    text-align: center;
  }
  
  .products-title {
    font-size: 36px;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Grid container for product cards */
  .products-grid {
    display: flex;
    flex-direction: row; /* Align items in a column */
    gap: 20px; /* Gap between each product card */
    align-items: center; /* Center the cards horizontally */
    justify-content: space-between;
  }
  
  /* Individual product card */
  .product-card {
    width: 100%;
    max-width: 400px; /* Set max width for a uniform column */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-image img {
    width: 100%;
    height: 200px;
    object-fit: cover; /* Maintain aspect ratio without distortion */
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .product-name {
    font-size: 20px;
    margin-top: 10px;
    color: #333;
    font-weight: 600;
  }
  
  .product-description {
    font-size: 16px;
    color: #555;
    margin-top: 10px;
  }
  
  .product-price {
    font-size: 18px;
    color: #ff9900;
    font-weight: bold;
    margin-top: 5px;
  }
  
  /* Hover effect for product cards */
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  }

/* Make the entire card clickable */
.product-card a {
    text-decoration: none;
    color: inherit; /* Ensure link doesn't change color */
    display: block;
}

.section-title {
    text-align: center;
    font-size: 50px !important;
    color: #218838;
}
/* General Mobile View Styles */
@media (max-width: 768px) {
  /* Adjust Welcome Section */
  .welcome {
    padding: 30px 15px;
  }

  .welcome h1 {
    font-size: 28px; /* Smaller font for smaller screens */
  }

  .welcome p {
    font-size: 16px;
  }

  .shop-now {
    font-size: 14px;
    padding: 8px 15px; /* Smaller padding for buttons */
  }

  /* Adjust Hero Section */
  .hero {
    height: auto;
  }

  .slider-image {
    object-fit: cover; /* Keep images responsive */
    height: 200px; /* Restrict height for mobile */
  }

  /* Adjust Product Section */
  .products-section {
    padding: 20px 10px;
  }

  .products-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .products-grid {
    flex-direction: column; /* Stack product cards vertically */
    gap: 15px; /* Adjust gap for smaller screens */
    justify-content: center; /* Center the product cards */
  }

  .product-card {
    width: 100%; /* Full width for each card */
    max-width: 100%; /* Remove max width restriction */
    padding: 15px;
  }

  .product-image img {
    height: 150px; /* Smaller image height */
  }

  .product-name {
    font-size: 18px;
  }

  .product-description {
    font-size: 14px;
  }

  .product-price {
    font-size: 16px;
  }

  /* Section Title Adjustments */
  .section-title {
    font-size: 32px; /* Reduce title size for mobile */
  }
}
