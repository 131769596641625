.cart-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    min-height: 50vh;
    font-size: 15px;
}

.cart-empty {
    text-align: center;
    margin-top: 20px;
}

.back-to-shop-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    border-radius: 5px;
    text-transform: uppercase;
}

.back-to-shop-button:hover {
    background-color: #45a049;
}


.cart-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.cart-table th, .cart-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.cart-table th {
    background-color: #f8f8f8;
    font-weight: bold;
}

/* Style for product info to stack the image and name vertically */
.product-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

.cart-item-image {
    width: 100px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 5px;
}


.quantity-controls button {
    padding: 5px 10px;
    font-size: 16px;
    margin: 0 5px;
    cursor: pointer;
}

.remove-button {
    background-color: #ff4d4d;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.cart-summary {
    text-align: right;
    margin-top: 20px;
   
}

.cart-summary p {
    font-size: 25px;
}

.cart-summary .total {
    font-weight: bold;
    font-size: 30px;
}

.checkout-button {
    display: inline-block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(255, 0, 0); /* Change to desired color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.checkout-button:hover {
    background-color: #007bff; /* Darker shade for hover effect */
}

.add-more-items-button {
    background-color: #316093;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-more-items-button:hover {
    background-color: #0056b3;
}


@media (max-width: 768px) {

    span{
        font-size: 15px;
        text-align: center;
    }

    .cart-table {
        width: 100%;
        display: table; /* Ensure table layout stays intact */
    }

    .cart-table th,
    .cart-table td {
        text-align: center; /* Center align text */
        padding: 10px;
        display: table-cell; /* Maintain table-cell layout */
    }

    .cart-table th {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        background-color: #f4f4f4;
    }

    .cart-item-image {
        width: 50px;
        height: 50px;
        margin: 0 auto; /* Center the image */
    }

    .cart-table td {
        border-bottom: 1px solid #ddd;
        vertical-align: middle;
        text-align: center;
    }

    /* Quantity controls */
    .quantity-controls {

        display: flex;
        justify-content: center; /* Center the controls */
        align-items: center;
    }

    .quantity-controls button {
        padding: 5px 5px;
        font-size: 10px;
        margin: 0 5px;
    }

    /* Remove button styling */
    .remove-button {
        padding: 8px 12px;
        font-size: 14px;
        background-color: #f44336;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }

    /* Ensure full table fits */
    .cart-page {
        overflow-x: auto; /* Allow horizontal scrolling if necessary */
    }
}

@media (max-width: 480px) {
    .cart-table {
        width: 100%;
    }

    .cart-table th,
    .cart-table td {
        font-size: 12px; /* Slightly smaller font for smaller screens */
        padding: 8px;
    }

    .quantity-controls button {
        font-size: 12px;
        padding: 4px 8px;
    }

    .remove-button {
        font-size: 12px;
        padding: 6px 8px;
    }

    .cart-item-image {
        width: 40px;
        height: 40px;
    }
}
