body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  font-size: 16px; /* Base font size */
}

body {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.6;

}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

}

/* Responsive Font and Layout Adjustments */
/* @media (max-width: 991px) {
  body {
      font-size: 0.9rem;
  }
  .container {
      padding: 10px;
  }
}

@media (max-width: 767px) {
  body {
      font-size: 0.8rem;
  }
  .container {
      padding: 5px;
  }
} */


