.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  background-color: #000; /* Optional: Add a background color */
  z-index: 9999; /* Ensure it stays on top */
  display: flex;
  align-items: center; /* Center video vertically */
  justify-content: center; /* Center video horizontally */
}


.preloader-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Full width for responsive scaling */
  max-width: 1200px; /* Restrict maximum width for larger screens */
  height: auto; /* Maintain aspect ratio */
  z-index: 1;
  object-fit: cover; /* Ensures the video fills the container */
  border-radius: 10px; /* Optional: Adds rounded corners for a polished look */
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fade-in 1s forwards;
}

.close-button:hover {
  background: #33ff00;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .loader {
    width: 500px;
    height: 400px;
  }

  .preloader-video {
    width: 90%; /* Slightly smaller width for tablets */
    max-width: 500px; /* Limit size on medium screens */
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .loader {
    width: 200px;
    height: 200px;
  }

  .preloader-video {
    width: 85%; /* Smaller width for mobile devices */
    max-width: 400px; /* Limit size further on small screens */
  }

  .close-button {
    top: 8px;
    right: 8px;
    font-size: 12px;
    width: 25px;
    height: 25px;
  }
}
