/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  color: #333;
  scroll-behavior: smooth;
}

/* About Page Styling */
.about-page {
  padding: 0;
  margin: 0;
}

/* Hero Section */
.about-hero {
  background: linear-gradient(to right, #60e055, #20b1ff);
  color: white;
  text-align: center;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.about-hero:hover {
  transform: translateY(-5px);
}

.about-hero .hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  animation: fadeIn 2s ease-in-out;
}

.about-hero .hero-content p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  max-width: 600px;
  line-height: 1.8;
  animation: fadeInUp 2s ease-in-out;
}

/* About Section */
.about-content {
  padding: 60px 20px;
  background-color: #fff;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.about-text {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  background: #fdfdfd;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-text:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.2);
}

.about-text h2 {
  font-size: 2.5rem;
  color: #60e055;
  margin-bottom: 15px;
  position: relative;
  text-align: center;
}

.about-text h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: #ff9671;
  margin-top: 8px;
  margin: 0 auto;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  text-align: justify;
  text-align: center;
  font-weight: 600;
}

/* Back-to-Top Button */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff9671;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
}

.back-to-top:hover {
  transform: scale(1.1);
  background-color: #60e055;
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-hero .hero-content h1 {
    font-size: 2.5rem;
  }

  .about-hero .hero-content p {
    font-size: 1rem;
  }

  .content-container {
    flex-direction: column;
  }

  .about-text h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .about-hero .hero-content h1 {
    font-size: 2rem;
  }

  .about-hero .hero-content p {
    font-size: 0.9rem;
  }

  .about-text p {
    font-size: 0.9rem;
  }
}
