.back-to-top {
    position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff; /* Adjust color as needed */
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  }
  

  
  .back-to-top.visible {
    display: block; /* Show the button when scrolled down */
  }
  
  
  .back-to-top-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #88edff; /* Customize the button color */
    color: white;
    border: none;
    border-radius: 50%;
    width: 70px; /* Customize size */
    height:70px; /* Customize size */
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  .back-to-top:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  