/* shop.css */

.shop-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.shop-header {
    display: flex;
    justify-content: end;
    padding: 20px;
    background-color: #f8f8f8;
}

.search-bar {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding:10px
}

.hero-section {
    position: relative;
}

.hero-image {
    width: 100%;
    height: auto;
}

.hero-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
}

.product-section {
    padding: 20px;
    background-color: #f9f9f9;
}

.product-heading {
    text-align: center;
    margin-bottom: 20px;
}

.heading {
    font-size: 2em;
    font-weight: bold;
    color: #333;
}

.product-section {
    padding: 20px 30px; /* Added padding to the left and right */
    background-color: #f9f9f9;
}

.product-cards {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap:100px; /* Space between each card */
    justify-content:space-between; /* Center the grid */
    margin: 0 auto;
}

.product-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    width: 100%; /* Ensures the card uses the available space within its grid cell */
    max-width: 300px; /* Set a maximum width for the card */
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.product-name {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-price {
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.product-description {
    font-size: 11px;
    color: #555;
    margin-bottom: 15px;
    text-align: center;
}

.buy-button {
    display: inline-block;
    margin-top: auto; /* Pushes button to the bottom */
    padding: 10px 5px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
    text-align: center;
}

.buy-button:hover {
    background-color: #0056b3;
}

.promotional-banners, .testimonials {
    margin: 20px 0;
}

.section-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.shop-footer {
    padding: 20px;
    background-color: #f8f8f8;
    text-align: center;
}

.informational-banner-section {
    text-align: center;
    margin: 20px 0;
}

.informational-banner-image {
    width: 100%;
    max-width: 1200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.cart-count {
    background-color: rgb(255, 0, 0) ;
    color: white;
    font-size: 0.8em;
    padding: 5px 5px;
    border-radius: 80%;
    
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

@keyframes grow {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.blink-and-grow {
    animation: blink 0.5s ease-in-out 2, grow 0.5s ease-in-out;
}

.view-cart-button {
    background-color: rgb(2, 124, 255);
    margin: 30px auto; /* Centers the button */
    padding: 10px 100px !important;
    border-radius: 80px;
    color: white;
    font-size: 20px;
    text-align: center;
    display: block; /* Ensures it's treated as a block-level element */
}

.view-cart-button:hover {
    transform: scale(0.8);
    transition: transform 0.3s;
    background-color: rgb(0, 92, 197);
    border-radius: 80px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-image1 {
    height: 200px; /* Ensure equal height and width */
    width: 100%; /* Make width 100% to ensure the image fills the container */
    object-fit: cover; /* Maintain the aspect ratio without stretching */
}

.product-name {
    font-size: 1.1em;
}

.product-price {
    font-size: 1em;
}

.product-description {
    font-size: 0.9em;
}

.buy-button {
    font-size: 1em;
    padding: 8px 16px;
}

.view-cart-button {
    font-size: 1em;
    padding: 12px;
}



@media screen and (max-width: 768px) {
    .product-cards {
        grid-template-columns: 1fr; /* Single column layout for mobile view */
        justify-content: center; /* Center the product cards horizontally */
        align-items: center; /* Center the product cards vertically */
    }

    .product-card {
        margin: 0 auto; /* Center each card within its column */
    }

    .product-image {
        height: 200px; /* Ensure equal height and width */
        width: 100%; /* Make width 100% to ensure the image fills the container */
        object-fit: cover; /* Maintain the aspect ratio without stretching */
    }

    .product-name {
        font-size: 1.1em;
    }

    .product-price {
        font-size: 1em;
    }

    .product-description {
        font-size: 0.9em;
    }

    .buy-button {
        font-size: 1em;
        padding: 8px 16px;
    }

    .view-cart-button {
        font-size: 1em;
        padding: 12px;
    }
}

