/* Checkout Page Container */
.checkout-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180vh;
    background-color: #dddddd;
    font-family: 'Arial', sans-serif;
    padding: 20px;
}

/* Checkout Form Container */
.checkout-container {
    display: flex;
    flex-direction: row; /* Ensures items are placed in a row */
    max-width: 1200px;
    height: 1200px;
    width: 100%;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 20px;
    transition: all 0.3s ease-in-out;
}

.checkout-container:hover {
    background-color: #ffffff;
    color: #fff;
}



/* Left Side - Form */
.checkout-form {
    flex: 1;
    margin-right: 20px;
    max-width: 800px;
}

.checkout-form h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
}

.checkout-form input, 
.checkout-form textarea {
    width: 70%;
    padding: 12px;
    margin: 10px 0;
    border: 2px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.checkout-form input:focus,
.checkout-form textarea:focus {
    border-color: #4CAF50;
    outline: none;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.4);
}

.checkout-form label {
    font-size: 15px;
    margin-right: 15px;
    cursor: pointer;
    display: block;
    color: #000000;
}

.checkout-form input[type="radio"] {
    margin-right: 10px;
}

/* Payment Button - Rainbow Border Animation */
.pay-button {
    display: inline-block;
    padding: 15px 40px;
    border: 2px solid transparent;
    border-radius: 50px;
    background-color: #01ddff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: all 0.4s ease-in-out;
    margin-top: 20px;
}

.payment-image {
    width: 100px;  /* Adjust image size */
    height: auto;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pay-button:hover {
    background-color: #222;
    color: #fff;
    animation: glow-border-clockwise 2s infinite;
}

.back-to-cart-button{
    background-color: skyblue;
    border-radius: 50px;
    padding: 10px;
}

.back-to-cart-container {
    margin-top: 20px;
    text-align: center;
}

.back-to-cart-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.back-to-cart-button:hover {
    background-color: #0056b3;
}

.add-more-items-indicator {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
}

.mandatory {
    color: red;
    margin-left: 5px;
}

.submit-button, .pay-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover, .pay-button:hover {
    background-color: #45a049;
}




.add-more-items-indicator {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #ff1818;
    font-weight: bold;
    animation: blink-text 1s infinite; /* Add blinking animation to text */
    text-align: center; /* Center-align the text */
}


/* Keyframes for Clockwise Border Glow Animation */
@keyframes glow-border-clockwise {
    0% {
        border-color: rgba(255, 0, 247, 0.7);
        box-shadow: 0 0 5px rgba(255, 0, 247, 0.7), 0 0 20px rgba(255, 0, 247, 0.7);
    }
    25% {
        border-color: rgba(89, 0, 255, 0.6);
        box-shadow: 5px 0 20px rgba(89, 0, 255, 0.6), 10px 0 30px rgb(4, 255, 0);
    }
    50% {
        border-color: rgba(0, 255, 247, 0.7);
        box-shadow: 0 5px 20px rgba(0, 255, 247, 0.7), 0 10px 30px rgba(25, 0, 255, 0.7);
    }
    75% {
        border-color: rgba(0, 255, 255, 0.8);
        box-shadow: -5px 0 20px rgba(0, 255, 255, 0.8), -10px 0 30px rgb(255, 247, 0);
    }
    100% {
        border-color: rgba(255, 0, 247, 0.7);
        box-shadow: 0 0 5px rgba(255, 0, 247, 0.7), 0 0 20px rgb(255, 123, 0);
    }
}

/* Right Side - Order Summary */
.order-summary {
    flex: 0.4;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px !important;
    
}


.order-summary h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.order-summary ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    
    
}

.order-summary ul li {
    font-size: 12px;
    margin: 10px 0;
    color: #555;
    color: #ff0101;
    font-weight: 800;
}

.order-summary h4 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
}

 .payment-options h2 {
    text-align: center;
    margin-top: 20px;
}

.payment-option {
 
    justify-content: center; /* Centers the content horizontally */
    align-items: center;    /* Centers the content vertically */
    margin-left: 50px;
 
}

.payment-options {
    display:grid;
    justify-content: center; /* Centers the content horizontally */
    align-items: center;    /* Centers the content vertically */
    height: 100px;          /* Adjust the height as needed */
}



.payment-image {
    width: 100px;
    height: auto;
    margin-left: 20px;
} 

.qr-code-container {
    text-align: center;
    margin-top: 20px;
}

.qr-code-container h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.qr-code-image {
    width: 200px;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 10px;
}


/* Mobile Responsiveness */
@media (max-width: 768px) {

    .checkout-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 220vh;
        background-color: #dddddd;
        font-family: 'Arial', sans-serif;
        padding: 20px;
    }

    .checkout-container {
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .checkout-form {
        margin-right: 0;
        margin-bottom: 50px;
        height:auto;
    }

    .pay-button {
        font-size: 16px;
        padding: 12px 0;
    }

    .order-summary {
        margin-top: 20px;
        width: 100%;
        max-width: 300px;
    }
}

/* Input Focus Glow Effect */
input:focus, textarea:focus {
    border: 2px solid #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.4);
}


/* Hide default radio button */
.payment-option input[type="radio"] {
    display: none;
}

/* Style for the image buttons */
.payment-option {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
}

.payment-image {
    width: 80px;  /* Adjust image size */
    height: auto;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.payment-option input[type="radio"]:checked + .payment-image {
    transform: scale(1.05); /* Slightly enlarge image when selected */
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.7); /* Highlight with a green glow */
}

/* Hover effect */
.payment-option:hover .payment-image {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(0, 128, 0, 0.6);
}

/* Styling for quantity buttons */
.quantity-btn {
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}


.quantity-btn:hover {
    background-color: #ddd;
}

.form-error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}
