.main-product-container {
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f0f4ff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Styling the product section */
.product-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  transition: background-color 0.3s ease;
 
}

.product-section:hover {
  background-color: #f0f0f0; /* Subtle hover effect */
}

/* Columns for product layout */
.product-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 600px;
}

/* Individual product column styles */
.product-column {
  flex: 5;
  margin: 0 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  font-size: 15px;
}


.product-column:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.slider-image{
  max-height: 800px;
}

/* Image container */
.product-image-container {
  text-align: center;
  margin-bottom: 20px;
}

/* Image styling */
.product-image {
  width:100%;
  height: 100% !important;
  border-radius: 10px;
  transition: transform 0.5s; /* Image zoom effect */
}

.product-image:hover {
  transform: scale(1.05); /* Zoom on hover */
  max-width: 100%;
}

/* Product content styling */
.product-content {
  text-align: center;
}

.product-title {
  font-size: 50px;
  color: #003366;
  margin-bottom: 20px;
  font-weight: bold; /* Bold title */
  text-align: center;
}

.product-subtitle {
  font-size: 30px;
  color: #00509e; /* Slightly different color for variety */
  margin-bottom: 10px;
}

.product-description {
  font-size: 15px;
  color: #333;
  margin-bottom: 20px;
}

/* Call to action buttons */
.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.buy-button, .subscribe-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold; /* Bold button text */
  transition: background-color 0.3s;
}

.products-section {
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background color for contrast */
}

.products-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.product1 {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff; /* White card-like background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
}

.product1:nth-child(even) {
  flex-direction: row-reverse; /* Alternate image positions */
}

.product1-content {
  flex: 1;
}

.product1-content h3 {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 10px;
  text-align:justify;
}

.product1-content p {
  font-size: 20px;
  line-height: 1.5;
  color: #000000;
  text-align:justify;
}

.product1-content ul {
  margin-top: 10px;
  padding-left: 20px;
  text-align:justify;
  font-size: 20px;
}

.product1-content ul li {
  font-size: 20px;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 5px;
  text-align:justify;
}

.product1-image {
  flex: 1;
  text-align: center;
}

.product1-image img {
  width: 100%;
  max-width: 400px; /* Adjust size as needed */
  height: auto;
  border-radius: 10px;
  object-fit: cover; /* Ensures proper cropping */
}


/* Product features section */

.pure-milk-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #eaeaea;
}

.pure-milk-container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  gap: 30px;
}

/* First Column - Image */
.pure-milk-image-column {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  position: relative;
}

.pure-milk-image {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.top-left-label,
.top-right-label,
.bottom-center-label {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #444;
  border-radius: 8px;
}

.top-left-label {
  top: 10px;
  left: 10px;
  background-color: #88c8e3;
}

.top-right-label {
  top: 10px;
  right: 10px;
  background-color: #88c8e3;
}

.bottom-center-label {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #88c8e3;
}

/* Second Column - Content */
.pure-milk-content-column {
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-family:cursive;
  gap: 20px;
}

.pure-milk-heading {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: left;
  font-family: cursive;
}

.pure-milk-list {
  list-style: none;
  padding: 0;
}

.pure-milk-list li {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.cow-icon {
  margin-right: 10px;
  font-size: 20px;
}

.explore-products-button {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.explore-products-button:hover {
  background-color: #218838;
}

/* Responsive design */
@media (max-width: 768px) {
  .product-columns {
    flex-direction: column;
    height:auto;
  }

  .popular-products-section, .health-benefits-section {
    margin-top: 50px;
  }

  .section-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .popular-products {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .product-card {
    text-align: center;
    width: 150px;
  }

  .product-price {
    font-weight: bold;
    color: green;
  }

  
  .product-columns {
    flex-direction: column; /* Stack items vertically on mobile */
    gap: 20px;
  }

  .product-column {
    width: 100%; /* Full width on mobile */
    margin: 0 auto; /* Centered */
  }

  .pure-milk-container {
    flex-direction: column; /* Stack columns on top of each other */
    text-align: center;
  }

  .pure-milk-image-column,
  .pure-milk-content-column {
    max-width: 100%; /* Take full width on small screens */
  }

  .pure-milk-heading {
    font-size: 1.5rem;
  }

  .pure-milk-list li {
    font-size: 0.9rem;
  }

  .explore-products-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .top-left-label,
  .top-right-label,
  .bottom-center-label {
    font-size: 10px;
    padding: 3px 8px;
  }  

  .product-image {
    width: 100%;
    max-width: 250px;
    height: 100px;
    height: auto;
    border-radius: 8px;
  }

  .product-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .product-subtitle {
    font-size: 1.4rem;
    color: #f6a600;
    text-align: center;
    margin-bottom: 10px;
  }

  .product-description {
    font-size: 1rem;
    color: #666;
    text-align: center;
    margin-bottom: 10px;
  }

  .product-features {
    font-size: 0.9rem;
    color: #444;
    text-align: left;
    padding: 0 10px;
    line-height: 1.5;
  }

  
  .product-columns {
    flex-direction: column;
    gap: 30px;
  }

  .product-column {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .product-title {
    font-size: 2rem;
  }

 

  .product-subtitle {
    font-size: 1.4rem;
  }

  .product-description {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .product-features {
    font-size: 0.9rem;
  }

  

  .product-heading {
    font-size: 25px;
  }

  .product-heading:hover {
    transform: scale(1.09);
    transition: 0.5s;
  }
}
