/* Center the heading container on the page */
.lab-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure it's vertically centered */
    background-color: #ffffff; /* Optional: dark background to make the glow effect stand out */
    padding: 0 20px;
    font-family: Arial, sans-serif;
}

/* Add styling for the heading */
.report-heading {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    animation: glow 1.5s ease-in-out infinite alternate;
}


/* Icons style */
.lab-icon {
    font-size: 2rem;
    animation: pulse 2s infinite;
}

/* Pulsing animation for icons */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}

/* Folder and images styles */
.folder-view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 50px;
}

.folder-card {
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
    overflow: hidden;
    box-sizing: border-box;
}

.folder-card:hover {
    transform: scale(1.05);
}

.folder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    flex-grow: 1;
}

.folder-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 400px;
    border-radius: 5px;
}

.folder-name {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    padding: 10px;
}

.image-view {
    text-align: center;
    padding: 20px;
}

.back-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.back-button:hover {
    background-color: #0056b3;
}

.images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.image-card {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    height: auto;
}

.image-card:hover {
    transform: scale(1.05);
}

.image-card img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
}

/* Media Queries */
@media (max-width: 768px) {
    .folder-view {
        grid-template-columns: repeat(2, 1fr);
    }

    .images {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .folder-view {
        grid-template-columns: 1fr;
    }

    .images {
        grid-template-columns: 1fr;
    }
}
